import { CSSProperties, useContext, useEffect, useState } from "react"
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"
import { useNavigate, Navigate, useLocation } from "react-router-dom"
import { ScrollContext } from "../../../contexts"
import "./arrowAnimation.css"

interface Props {
    upOrDown: "up" | "down"
    extraStyle?: CSSProperties
    linkTo?: string
    color?: {default: string, onHover: string}
}
export const ScrollArrow = (props: Props) => {

    const { color, extraStyle, upOrDown } = props
    const { isBottom, isTop, scrollRefs, isScrolling } = useContext(ScrollContext)
    
    const [hover, setHover] = useState<boolean>(false);

    const navigate = useNavigate()
    const location = useLocation()
    const animation = isScrolling? (
        {
            animation: "rotate 1s ease-in-out infinite", 
            WebkitAnimation: "rotate 1s ease-in-out infinite"
        } 
    )
        :
    (     
        { 
            animation: "bounce 3s ease-in-out infinite", 
            WebkitAnimation: "bounce 3s ease-in-out infinite" 
        }
    )

    /**
     * Finds next page through ID and scrolls to the reference. 
     * If the reference has no ID takes the next elements ID or the next after that one.
    */
    const nextPage = (nextOrPrev: "next" | "prev") => {
        const pagesReferenceArr = Object.keys(scrollRefs.pages).map(function(key) {
            return scrollRefs.pages[key as keyof typeof scrollRefs.pages];
        });
        const currentIndex = pagesReferenceArr.findIndex((page) => 
            location.hash.split("#")[1] === (
                page.current?.id || 
                page.current?.nextElementSibling?.id || 
                page.current?.nextElementSibling?.nextElementSibling?.id
            )  
        )
        const newIndex = nextOrPrev === "next"? currentIndex + 1 : currentIndex - 1
        const nextPage = (
            pagesReferenceArr[newIndex]?.current?.id || 
            pagesReferenceArr[newIndex]?.current?.nextElementSibling?.id || 
            pagesReferenceArr[newIndex]?.current?.nextElementSibling?.nextElementSibling?.id
        )


        if(nextPage && !isScrolling) {
            navigate(`/#${nextPage}`)
        }

    }
    
    return (
        upOrDown === "up"? (
           
            <div 
                onMouseEnter={() => setHover(true)} 
                onMouseLeave={() => setHover(false)}
                style={{
                    ...generalBtnStyle, 
                    ...extraStyle,
                    color: hover? color?.onHover : color?.default
                }}
                onClick={() => {
                    if(!isTop) {
                        nextPage("prev")
                    } 
                }}
            >

                <div style={{...animation, zIndex: 99}}>
                    <AiOutlineArrowUp 
                        size={30} 
                        className={"crossBrowserSticky"}
                        style={{
                            transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
                            WebkitTransition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
                            transform: isTop? "rotate(360deg)" : "none" ,
                            
                            opacity: (isTop)? 0 : 1,
                        }} 
                    />
                </div>
            </div>

        ) : ( /* Else down */
            
            <div 
                onMouseEnter={() => setHover(true)} 
                onMouseLeave={() => setHover(false)}
                style={{
                    ...generalBtnStyle, 
                    ...extraStyle,
                    color: hover? color?.onHover : color?.default
                }}
                onClick={() => {
                    if(!isBottom) {
                        nextPage("next")
                    } else {
                        navigate("/#start")
                    }
                }}
            >

                <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center", flexWrap: "nowrap", zIndex: 99}}>

                    <div style={{...animation}}>

                        <AiOutlineArrowDown 
                            size={30} 
                            className={"crossBrowserSticky"}
                            style={{
                                transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
                                WebkitTransition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
                                transform: isBottom? "rotate(540deg)" : "rotate(720deg)",
                            }} 
                        />
                    </div>
                   
                    <div style={{maxWidth: isBottom? "100%" : 0, overflow: "hidden", transition: "max-width 1s ease-in-out", WebkitTransition: "max-width 1s ease-in-out"}}>
                        <p style={{pointerEvents: "none", fontSize: "1.2em", margin: 0, whiteSpace: "nowrap", color: hover? color?.onHover : color?.default}}>
                            To The Top
                        </p>
                    </div>
                  
                </div>
                
            </div>
                
        )
    )
}

const generalBtnStyle: CSSProperties = {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    flexDirection: "column",
    pointerEvents: "auto"
}