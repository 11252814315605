import { useContext } from "react"
import { ContactTitleAndBtn } from "./contactTitleAndBtn";
import { ContactIconMenu } from "./contactIconMenu";
import { ScrollContext } from "../../../contexts";
import { ScrollPage } from "../scrollPage";


export const Contact = () => {

    const { pageInView } = useContext(ScrollContext)
    const inView = pageInView === "contact"


    return (
        <ScrollPage id={"contact"} backgroundColor={"#0e1111"} innerStyle={{justifyContent: "center"}}>
            <div>
                <ContactIconMenu triggerStartAnimation={inView}/>
                <ContactTitleAndBtn triggerStartAnimation={inView}/>
            </div>
        </ScrollPage>
    )
}
