import { CSSProperties, RefObject } from "react"

interface Props {
    reference: RefObject<HTMLInputElement>
}

/** 
 * The point wich is used to trigger when element is in view
 * 
 * This needs to be placed above the element its refering to in order for the functions depending on this in the scrollContext to work.
 * */ 
export const ViewPoint = (props: Props) => {

    const { reference } = props

    return (    
        <div ref={reference} className={"viewPoint"} style={style} />
    )
}

const style: CSSProperties = {
    top: "50%", 
    left: "50%", 
    position: "relative",
    zIndex: "-99",
}
