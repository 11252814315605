import { createContext, RefObject, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IScrollContext {
    isBottom: boolean
    isTop: boolean
    scrollPosition: number
    /** Reference need to placed on the div that is scrolling*/ 
    setPageInView: (view: "start" | "aboutme" | "skills" | "contact") => void
    pageInView?: "start" | "aboutme" | "skills" | "contact"
    isScrolling: boolean
    scrollRefs: {
        wrapper: RefObject<HTMLInputElement>,
        pages: {
            start: RefObject<HTMLInputElement>
            aboutme: RefObject<HTMLInputElement>
            skills: RefObject<HTMLInputElement>
            contact: RefObject<HTMLInputElement>
        }
    }

}

interface Props {
    children: React.ReactNode;
}
  
export const ScrollContext = createContext<IScrollContext>({} as any);
export const ScrollProvider = (props: Props) => {
    
    const [scrollPosition, setScrollPosition] = useState<number>(0)
    const [isBottom, setIsBottom] = useState<boolean>(false)
    const [isTop, setIsTop] = useState<boolean>(true)
    const [pageInView, setPageInView] = useState<IScrollContext["pageInView"]>()
    const [isScrolling, setIsScrolling] = useState<boolean>(false)
    const [scrollRefs, setScrollPageRefs] = useState({
        wrapper: useRef<HTMLInputElement>(null),
        pages: {
            start: useRef<HTMLInputElement>(null),
            aboutme: useRef<HTMLInputElement>(null),
            skills: useRef<HTMLInputElement>(null),
            contact: useRef<HTMLInputElement>(null),
        }
    })

    const navigate = useNavigate()
    const location = useLocation() 
    const prevHash = useRef<string>()

    //Scrolls to reference when location changes
    useEffect(() => {
        if(!location.hash) { return }
        //Making object into array
        const pagesReferenceArr = Object.keys(scrollRefs.pages).map(function(key) {
            return scrollRefs.pages[key as keyof typeof scrollRefs.pages];
        });
        
        pagesReferenceArr.forEach((page) => {
            const id = (
                page.current?.id || 
                page.current?.nextElementSibling?.id || 
                page.current?.nextElementSibling?.nextElementSibling?.id
            )

            if (id && location.hash.includes(id)) {   
               
                const target = id === page.current.id? (
                    page.current
                ) : page.current.nextElementSibling?.className === "scrollAnchor"? (
                    page.current.nextElementSibling
                ) : (
                    page.current?.nextElementSibling?.nextElementSibling
                )
            
                if(!target) { return }
                target.scrollIntoView({
                    behavior: prevHash.current? 'smooth' : "auto",
                    block: 'start',            
                });
                
            } 
        })
        
        prevHash.current = location.hash

    }, [ location.hash, scrollRefs ])

    //Scroll listener responsible for isScrolling, scrollPosition, isTop & isBottom
    useEffect(() => {
        const target = scrollRefs.wrapper.current
        if(!target) { return }

        let handle: any = null;

        const onScroll = () => {
           
            setIsScrolling(true)

            const scrollPx = target.scrollTop;
            const winHeightPx = target.scrollHeight - target.clientHeight;
            const scrolled = scrollPx / winHeightPx * 100;
            const bottom = Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1
            

            bottom? setIsBottom(true) : setIsBottom(false)
            scrolled < 1? setIsTop(true) : setIsTop(false)
            
            setScrollPosition(scrolled)

            if (handle) {
                clearTimeout(handle);
            }

            handle = setTimeout(() => {
                setIsScrolling(false)
            }, 200); 
        };      
        
        target.addEventListener('scroll', onScroll);
        return () => target.removeEventListener('scroll', onScroll)

    }, [scrollRefs.wrapper])

    //sets url hash to pageInView when scrolling stops
    useEffect(() => {
        if(isScrolling) { return }
        if(!pageInView) { return }
        navigate(`/#${pageInView}`)

    }, [isScrolling, pageInView, navigate])
 
    //Sets pageInView to target ID, if target ID doesnt exist takes the next elements ID, if that doesnt exists takes the one after that. 
    //This way it works both when the ref is on view points, anchors or the element itself
    useEffect(() => {        
        const pagesReferenceArr = Object.keys(scrollRefs.pages).map(function(key) {
            return scrollRefs.pages[key as keyof typeof scrollRefs.pages];
        });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {

                if(entry.isIntersecting) {  
                    const id = (
                        entry.target.id ||
                        entry.target.nextElementSibling?.id ||
                        entry.target.nextElementSibling?.nextElementSibling?.id
                    )
                    if(!id) { return }

                    setPageInView(id as IScrollContext["pageInView"])
                }
            }, {threshold: 0.6});
        })
        
        pagesReferenceArr.forEach((page) => {
            if (!page.current) { return }
            observer.observe(page.current);
        })
           
        return () => {
            pagesReferenceArr.forEach((page) => {
                if (!page.current) { return }
                observer.unobserve(page.current)
            })
        }

    }, [scrollRefs, navigate] );


    return (
        <ScrollContext.Provider
            value={{
                scrollPosition,
                isBottom,
                isTop,
                setPageInView,
                pageInView,
                scrollRefs,
                isScrolling
            }}
        >
          
            {props.children}
        
        </ScrollContext.Provider>
    )
}