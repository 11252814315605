import { useContext, useState } from "react"
import { ScrollContext } from "../../../contexts"
import { ScrollPage } from "../scrollPage"
import { SkillsIcons } from "./skillsIcons"
import { SkillsText } from "./skillsText"
import { ViewportContext } from "../../../contexts"

export const Skills = () => {
    
    const { pageInView } = useContext(ScrollContext)
    const { width } = useContext(ViewportContext)

    const [allIconsVisible, setAllIconsVisible] = useState<boolean>(false)
    const [textVisible, setTextVisible] = useState<boolean>(false)
    const inView = pageInView === "skills"
    


    return (
        <ScrollPage backgroundColor={"#0e1111"} id="skills" innerStyle={{justifyContent: "center"}} outerStyle={{paddingTop: "10vh"}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: width > 475? "450px" : "95%",}}>
                <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "end", textAlign: "center"}}>
         
                    <div style={{
                        display: "flex",
                        width: textVisible? "100%" : 0,
                        height: "100%",
                        backgroundColor: "lightgray",
                        transition: "0.5s ease-out",
                        WebkitTransition: "0.5s ease-out",
                        margin: "0 0 0 5px",
                        flexDirection: "column"
                    }} />
                        
                    <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
                        <h1 style={{fontSize: "1.2em", color: "lightgray", margin: "0 0 0 0"}}>
                            Skills
                        </h1>
                    </div>
                </div>
                <SkillsText setTextVisible={setTextVisible} allIconsVisible={allIconsVisible}/>
            </div>
            <SkillsIcons setAllIconsVisible={setAllIconsVisible} inView={inView}/>
        </ScrollPage>
    )
}
