import { useContext } from "react"
import { ScrollContext, ViewportContext } from "../../contexts"
import { AboutMe } from "./aboutMe"
import { Contact } from "./contact"
import { Skills } from "./skills"
import { Start } from "./start"
import atariBg from "../../assets/atari-2600-3997752.png"
import nintendoBg from "../../assets/nintendo-entertainment-system-4003973.png"
import gameboyBg from "../../assets/nintendo-gameboy-4003938.png"
import { ImgDivider } from "../ui";
import { ViewPoint, ScrollAnchor } from "../utils";

export const Layout = () => {

    const { scrollRefs } = useContext(ScrollContext)
    const { width } = useContext(ViewportContext)


    return (
     
        <div 
            ref={scrollRefs.wrapper} 
            id="wrapper" 
            className="noScrollBar"
            style={{
                height: "100vh",
                overflow: "auto",
            }} 
        >

            <ViewPoint reference={scrollRefs.pages.start}/>
            <ScrollAnchor />
            <Start />
            <ImgDivider bg={`url(${atariBg}), rgb(237,39,36)`} height={width > 475? "150px" : "100px"}/> 
           
            <ViewPoint reference={scrollRefs.pages.aboutme}/>
            <ScrollAnchor />
            <AboutMe />
            <ImgDivider bg={`url(${nintendoBg}), rgb(249, 150, 14)`} height={width > 475? "150px" : "100px"}/>
            
            <ViewPoint reference={scrollRefs.pages.skills}/>
            <ScrollAnchor />
            <Skills />
            <ImgDivider bg={`url(${gameboyBg}), rgb(0, 133, 50)`} height={width > 475? "150px" : "100px"}/>
            
            <ViewPoint reference={scrollRefs.pages.contact}/>
            <ScrollAnchor />
            <Contact />
            

        </div>
    )
}

