import { useEffect } from "react"

interface Props {
    allIconsVisible: boolean
    setTextVisible: (bool: boolean) => void
}
export const SkillsText = (props: Props) => {

    const { allIconsVisible, setTextVisible } = props

    useEffect(() => {
        if(allIconsVisible) {
            const animationTimeout = setTimeout(() => {
                setTextVisible(true)
            }, 1000)
    
            return () => { clearTimeout(animationTimeout) }
        }
        else {
            const animationTimeout = setTimeout(() => {
                setTextVisible(false)
            }, 200)
    
            return () => { clearTimeout(animationTimeout) }
        }
        
    }, [allIconsVisible, setTextVisible])

    return(
 
        <div style={{
            display: "flex", 
            flexDirection: "column",
            justifyContent: "center", 
            fontFamily: "VT323, monospace",
            backgroundColor: "rgba(250, 250, 250, 0.5)", 
            padding: "15px",
            position: "relative", 
            transition: "left 1s",
            WebkitTransition: "left 1s",
            left: allIconsVisible? 0 : -2000,
            boxShadow: "rgb(0 0 0 / 60%) 10px 10px 5px 3px",
            marginBottom: "10vh"
        }}>
            <span style={{fontSize: "1.2em"}}>
                Under the course of my studies I have acquired knowledge of all of the languages and tech you see here but React, 
                Node.js and Javascript have found a special place in my heart and are the techs I worked with the most.
            </span>
        </div>

    )
}
