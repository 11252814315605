import { CSSProperties, ReactChild } from "react"
import { ImgDivider } from "../ui"
import atariBg from "../../assets/atari-2600-3997752.png"

interface Props {
    children?: ReactChild | ReactChild[]
    id: "start" | "aboutme" | "skills" | "contact"
    backgroundColor?: string,
    color?: string
    outerStyle?: CSSProperties
    innerStyle?: CSSProperties
}

export const ScrollPage = (props: Props) => {

    const {children, id, backgroundColor, color, outerStyle, innerStyle} = props

    return (
        
        <div 
            id={id} 
            className={"crossBrowserSticky"}
            style={{
                ...outerWrapp, 
                ...outerStyle,
                height: `100vh`, 
                backgroundColor: backgroundColor,
                color: color,
            }}
        >
            
            <div className={"noScrollBar"} style={{...innerPage, ...innerStyle}}>
                {children}
            </div>
        
        </div>
    
    )
}

const outerWrapp: CSSProperties = {
    overflow: "hidden",
    color: "black",
    backgroundColor: "rgb(151 137 114)",
    top: 0,
}

const innerPage: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto", 
    width: "100%", 
    height: "100%"
}