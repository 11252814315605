import { createContext, useEffect, useState } from "react";

interface IViewportContext {
    width: number 
    height: number 
    deviceSize?: "desktop" | "mobile" 
}

interface Props {
    children: React.ReactNode;
}

export const ViewportContext = createContext<IViewportContext>({} as any);
export const ViewportProvider = (props: Props) => { 


    const [width, setWidth] = useState<IViewportContext["width"]>(window.innerWidth)
    const [height, setHeight] = useState<IViewportContext["height"]>(window.innerHeight)
    const [deviceSize, setDeviceSize] = useState<IViewportContext["deviceSize"]>(window.innerHeight < 600? "mobile" : "desktop")

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
      }, []);

    //TODO: add tablet? width > 600 && width < 1200
    useEffect(() => {
        if(!width) { return }

        if(width < 600 ) {
            setDeviceSize("mobile")
        } else if (width > 600) {
            setDeviceSize("desktop")
        }
    }, [width])

    return (
        <ViewportContext.Provider
            value={{
                width,
                height,
                deviceSize,
            }}
        >
            {props.children}
        </ViewportContext.Provider>
    )
}
