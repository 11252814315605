import { CSSProperties, useEffect, useState } from "react"
import { Link } from "react-router-dom"
interface Props {
    text: string
    linkTo?: string
    color?: {default: string, onHover: string}
    onClick?: () => void
}
export const HoverBtn = (props: Props) => {

    const {text, color, linkTo, onClick} = props

    const [hover, setHover] = useState(false);
    
    const generalBtnStyle: CSSProperties = {
        padding: "0.5em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer"
    }

    useEffect(() => {
    }, [hover])

    return (
        linkTo? (
            <Link to={linkTo}>
                <div 
                    onMouseEnter={() => setHover(true)} 
                    onMouseLeave={() => setHover(false)}
                    onClick={() => onClick? onClick() : null}
                    style={generalBtnStyle}
                >

                    {/* The text with hover effect on > / . */}
                    <span style={{color: hover? color?.onHover : color?.default}}>
                        {hover? ">" : "."}
                        {text}
                    </span>
                </div>
            </Link>

        ): (
            
            <div 
                onMouseEnter={() => setHover(true)} 
                onMouseLeave={() => setHover(false)}
                onClick={() => onClick? onClick() : null}
                style={generalBtnStyle}
            >

                {/* The text with hover effect on > / . */}
                <span style={{color: hover? color?.onHover : color?.default}}>
                    {hover? ">" : "."}
                    {text}
                </span>
            </div>
        )

    )
}
