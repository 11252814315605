import { useEffect, useRef, useState } from "react"
import { AiOutlineGithub, AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai"

interface Props {
    triggerStartAnimation: boolean
}


export const ContactIconMenu = (props: Props) => {

    const { triggerStartAnimation } = props

    const [hoverIcons, setHoverIcons] = useState<"none" | "github" | "email" | "linkedIn">("none")
    const ref = useRef<any>()

    useEffect(() => {
        const allIcons = [...ref.current.children]
        if(triggerStartAnimation) { 
            let i = 0
            const renderOneAtTheTime = setInterval(() => {
                allIcons[i].style.opacity = 1
                i++
                if(i === allIcons.length) {
                    clearInterval(renderOneAtTheTime)
                }
            }, 250)
            
            return () => clearInterval(renderOneAtTheTime)

        } else {

            let i = allIcons.length - 1
            const renderOneAtTheTime = setInterval(() => {
                allIcons[i].style.opacity = 0
                i--
                if(i < 0) {
                    clearInterval(renderOneAtTheTime)
                }
            }, 250)
            
            return () => clearInterval(renderOneAtTheTime)
        }
        
    }, [triggerStartAnimation])
    
    return(
      
        <div 
            ref={ref} 
            style={{  
                height: "60px", 
                marginBottom: "15px", 
                display: "flex", 
                justifyContent: "space-between", 
                alignItems: "end", 
                transition: "1s ease-in",
                WebkitTransition: "1s ease-in"
            }}
        >
            
            <AiOutlineGithub 
                onMouseEnter={() => setHoverIcons("github")} 
                onMouseLeave={() => setHoverIcons("none")}
                onClick={() => window.open('https://github.com/TokJocke', '_blank')}
                style={{
                    color: hoverIcons === "github"? "white" : "lightgray",
                    cursor: "pointer",
                    fontSize: hoverIcons === "github"? "60px" : "50px",
                    opacity: 0,
                    transition: "0.1s ease-in",
                    WebkitTransition: "0.1s ease-in",
                }}
            />
    
            <AiOutlineMail 
                onMouseEnter={() => setHoverIcons("email")} 
                onMouseLeave={() => setHoverIcons("none")}
                onClick={() => window.open("mailto:joadanpfa@gmail.com", '_blank')}
                style={{
                    color: hoverIcons === "email"? "white" : "lightgray",
                    cursor: "pointer",
                    fontSize: hoverIcons === "email"? "60px" : "50px",
                    opacity: 0,
                    transition: "0.1s ease-in",
                    WebkitTransition: "0.1s ease-in",
                }}    
            />

            <AiOutlineLinkedin 
                onMouseEnter={() => setHoverIcons("linkedIn")} 
                onMouseLeave={() => setHoverIcons("none")}
                onClick={() => window.open('https://www.linkedin.com/in/joakim-pfannenstill-430487238/', '_blank')}

                style={{
                    color: hoverIcons === "linkedIn"? "white" : "lightgray",
                    cursor: "pointer",
                    fontSize: hoverIcons === "linkedIn"? "60px" : "50px",
                    opacity: 0,
                    transition: "0.1s ease-in",
                    WebkitTransition: "0.1s ease-in",
                }}
            />
        </div>
      
    )
    
}