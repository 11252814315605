import { useState } from "react";

interface Props {
    triggerStartAnimation: boolean
}
export const ContactTitleAndBtn = (props: Props) => {

    const { triggerStartAnimation } = props
    
    const [hoverTitle, setHoverTitle] = useState<boolean>(false);

    return(
        <div 
            onMouseEnter={() => setHoverTitle(true)} 
            onMouseLeave={() => setHoverTitle(false)} 
            onClick={() => window.open("mailto:joadanpfa@gmail.com", '_blank')}
            style={{ 
                cursor: "pointer", 
                display: "flex", 
                flexDirection: "column",
            }}
        >
            <div>
                <div 
                    style={{
                        display: "flex", 
                        width: hoverTitle? "50%" : triggerStartAnimation? "100%"  : 0, 
                        height: "5px", 
                        backgroundColor: hoverTitle? "white" : "lightgray", 
                        transition: "width 0.3s ease-in",
                        WebkitTransition: "width 0.3s ease-in",
                    }}
                />
            </div>

            <h1 
                style={{
                    fontSize: "1.2em", 
                    padding: "10px 0", 
                    color: hoverTitle? "white" : "lightgray",
                }}
            >
                contact me
            </h1>

            <div style={{display: "flex", flexDirection: "row-reverse", width: "100%"}}>
                <div style={{
                    width: hoverTitle? "50%" : triggerStartAnimation? "100%"  : 0, 
                    height: "5px", 
                    backgroundColor: hoverTitle? "white" : "lightgray", 
                    flexDirection: "row-reverse",
                    transition: "width 0.3s ease-in",
                    WebkitTransition: "width 0.3s ease-in",
                }}/>
            </div>

        </div>
    )

}

