import { CSSProperties } from "react"


/** 
 * An anchor point to use for scroll when the orginal element cant be targeted. 
 * */ 
export const ScrollAnchor = () => {


    return (    
        <div className={"scrollAnchor"} style={style} />
    )
}

const style: CSSProperties = {
    zIndex: "-99",
}
