import { PageIndicator } from "./pageIndicator"
import { ScrollArrow } from "./scrollArrow"

interface Props {

}

export const UiLayer = (props: Props) => {


    return (
   
        <div 
            className="ui-layer" 
            style={{
                height: "100%", 
                width: "100%", 
                position: "fixed", 
                zIndex: 99, 
                pointerEvents: "none",
            }}
        >

            <PageIndicator width={200}/>

            <ScrollArrow 
                upOrDown={"down"} 
                color={{
                default: "lightgray", 
                onHover: "white"
                }} 
                extraStyle={{
                position: "absolute", 
                bottom: 0, 
                right: 0
                }}
            /> 

            <ScrollArrow 
                upOrDown={"up"} 
                color={{
                    default: "lightgray", 
                    onHover: "white"
                }} 
                extraStyle={{
                    position: "absolute", 
                    top: 0, 
                    left: 0
                }}
            /> 
            
        </div>
     
    )
}
