import { CSSProperties } from "react"
import "./pacmanAnimations.css"

interface Props {
    minWidth: string
    minHeight: string
}
export const Pacman = (props: Props) => {




    return (
        
        <div style={{minWidth: props.minWidth, minHeight: props.minHeight}} className="pacman">
            <div style={{...topAndBottom, ...pacmanTop}} className="pacman-top"></div>
            <div style={{...topAndBottom, ...pacmanBottom}} className="pacman-bottom"></div>
        </div>
        
    )
}


const topAndBottom: CSSProperties = {
    backgroundColor: "#ff0",
    height: "50%",
    width: "100%",
    //position: "absolute",
}

const pacmanTop: CSSProperties = {
    top: 0,
    borderRadius: "100px 100px 0 0", 
}

const pacmanBottom: CSSProperties = {
    bottom: "0",
    borderRadius: "0 0 100px 100px",
}