
interface Props {
    bg: string
    height: string
}

export const ImgDivider = (props: Props) => {

    const { bg, height } = props

    return (
   
        <div
            className={"divider"}
            style={{
                
                background: bg,
                height: height,
                width: "100%",
                backgroundAttachment: "fixed", 
                backgroundPosition: "50%, 50vh", 
                backgroundRepeat: "no-repeat", 
                backgroundSize: "contain", 
                position: "relative",
            }} 
        />
     
    )
}
