import { CSSProperties, useEffect, useRef } from "react"
import { 
    SiReact, 
    SiTypescript, 
    SiNodedotjs, 
    SiHtml5, 
    SiMysql, 
    SiJavascript, 
    SiCss3, 
    SiGithub, 
    SiPhp, 
    SiWordpress } from "react-icons/si"

interface Props {
    inView: boolean
    setAllIconsVisible: (param: boolean) => void
}
export const SkillsIcons = (props: Props) => {

    const { inView, setAllIconsVisible } = props
    const ref = useRef<any>()


    useEffect(() => {
        const allIcons = [...ref.current.children]
        if(inView) { 
            let i = allIcons.length - 1
            const renderOneAtTheTime = setInterval(() => {
                allIcons[i].style.left = 0
                i--
                if(i < 0) {
                    setAllIconsVisible(true)
                    clearInterval(renderOneAtTheTime)
                }
            }, 200)
            return () => clearInterval(renderOneAtTheTime)
        }
        else {
            let i = 0
            const renderOneAtTheTime = setInterval(() => {
                allIcons[i].style.left = -2000
                i++
                if(i === allIcons.length) {
                    setAllIconsVisible(false)
                    clearInterval(renderOneAtTheTime)
                }
            }, 200)
            return () => clearInterval(renderOneAtTheTime)
        }
    }, [inView, setAllIconsVisible])

    return(
        <div ref={ref} style={{display: "flex", marginBottom: "30px", justifyContent: "center", flexWrap: "wrap"}}>
            <SiReact size={70} style={{...iconStyle}}/>
            <SiTypescript size={70} style={{...iconStyle}}/>
            <SiNodedotjs size={70} style={{...iconStyle}}/>
            <SiHtml5 size={70} style={{...iconStyle}}/>
            <SiMysql size={70} style={{...iconStyle}}/>
            <SiJavascript size={70} style={{...iconStyle}}/>
            <SiCss3 size={70} style={{...iconStyle}}/>
            <SiGithub size={70} style={{...iconStyle}}/>
            <SiPhp size={70} style={{...iconStyle}}/>
            <SiWordpress size={70} style={{...iconStyle}}/>
        </div>
    )
}

const iconStyle: CSSProperties = {
    transition: "0.5s ease-out",
    WebkitTransition: "0.5s ease-out",
    padding: "10px",
    position: "relative",
    left: "-2000",
    color: "lightgray"
}