import { ReactChild } from "react"
import { HoverBtn } from "../ui"
import { ScrollPage } from "./scrollPage"

interface Props {
    children?: ReactChild | ReactChild[]
}
export const Start = (props: Props) => {

    return (
        <ScrollPage id={"start"} backgroundColor={"#0e1111"}  innerStyle={{justifyContent: "center"}}>
            <HoverBtn linkTo="/#aboutme" color={{default: "lightgray", onHover: "white"}} text = {"about me"}/>                
            <HoverBtn linkTo="/#skills" color={{default: "lightgray", onHover: "white"}} text = {"skills"}/>                
            <HoverBtn linkTo="/#contact" color={{default: "lightgray", onHover: "white"}} text = {"contact"}/>               
        </ScrollPage>
    )
}
