import { CSSProperties, useContext} from "react"
import { ScrollContext } from "../../contexts"
import { ViewportContext } from "../../contexts/viewportContext"
import { ScrollPage } from "./scrollPage"
import selfie from "../../assets/pixelSelfie.png"

interface Props {
}
export const AboutMe = (props: Props) => {
    
    const { pageInView } = useContext(ScrollContext)
    const { width } = useContext(ViewportContext)

    const inView = pageInView === "aboutme"

    return (
        <ScrollPage backgroundColor={"#0e1111"} color={"black"} id="aboutme" outerStyle={{paddingTop: "20vh"}}>
                <div style={{display: "flex", flexDirection: "column",  maxWidth: width > 475? "450px" : "95%"}}>
                    {/* Title with effects/animations */}
                    <div>
                        
                        <div style={{width: "100%", display: "flex", alignItems: "center"}}>

                            {/* animation line */}
                            <div style={{
                                display: "flex", 
                                flexGrow: inView? 0 : 1, 
                                height: "5px", 
                                backgroundColor: "lightgray", 
                                transition: "0.5s",
                                WebkitTransition: "0.5s" 
                            }}/> 

                            <h1 style={{
                                margin: inView? "0 20px 0 0" : "0 40px", 
                                fontSize: "1.2em",
                                color: "lightgray",
                                transition: "0.5s ease-in-out",
                                WebkitTransition: "0.5s ease-in-out" 

                            }}>
                                about me
                            </h1>

                            {/* animation line */}
                            <div style={{
                                display: "flex", 
                                flexGrow: 1,
                                height: "5px", 
                                backgroundColor: "lightgray",
                                transition: "0.5s ease-in-out",
                                WebkitTransition: "0.5s ease-in-out"
                            }}/>

                        </div>
                        <div style={{
                            width: inView? "100%" : "0%", 
                            height: "5px", 
                            backgroundColor: "lightgray", 
                            marginTop: "5px",
                            transition: "0.5s ease-in-out",
                            WebkitTransition: "0.5s ease-in-out",
                        }}/>
                    </div>
                
                    {/* Information span with effects/animations */}
                    <div style={{...infoWrapper, maxHeight: inView? "100%" : 0, boxShadow: inView? "rgb(0 0 0 / 60%) 10px 10px 5px 3px" : "none",
}}>
                        
                        <div style={{...imgWrapper}}>
                            <img style={{width: "100%", objectFit: "contain"}} alt="selfie" src={selfie}/>
                        </div>
                        <div style={{padding: "15px"}}>
                            <span style={{color: "black"}}>
                                I am today 30 years old and have for the past ten years worked in the school world, 
                                where my patience and my problem solving abilities gave me a permanent job despite the lack of 
                                education in the field. Throughout my life I have always tried to keep the attitude that all knowledge is 
                                good knowledge and no knowledge is too heavy to carry. I have always welcomed new tasks with enthusiasm and 
                                tried to learn the most out of them. My great interest in IT has been a part of my 
                                everyday life in one form or another, which made the choice to become a web developer obvious.
                            </span>
                        </div>
                    </div>
                </div>

        </ScrollPage>
    )
}

const imgWrapper: CSSProperties = {
    width: "150px",
    height: "150px",
    borderRadius: "5px",
    float: "left",
    overflow: "hidden",
    margin: "15px 5px 0 15px",
    boxShadow: "5px 5px 15px rgba(68,68,68,0.6)",
}

const infoWrapper: CSSProperties = {
    width: "100%", 
    overflow: "hidden", 
    transition: "max-height 2s", 
    WebkitTransition: "max-height 2s", 
    backgroundColor: "rgba(250, 250, 250, 0.5)",
    fontFamily: "VT323, monospace",
    fontSize: "1.2em",
}
