import { CSSProperties, useContext, } from "react"
import { ScrollContext } from "../../contexts"
import { Pacman } from "./pacman"
interface Props {
    width: number
}

export const PageIndicator = (props: Props) => {

    const {scrollPosition, scrollRefs} = useContext(ScrollContext)
    const allCircles: string[] = []

    const pagesReferenceArr = Object.keys(scrollRefs.pages).map(function(key) {
        return scrollRefs.pages[key as keyof typeof scrollRefs.pages];
    });

    for(let i = 0; i < pagesReferenceArr.length; i++) {
        if(i !== pagesReferenceArr.length -1) {
            allCircles.push("big", "small", "small")
        } else {
            allCircles.push("big")
        }
    }

    return (
             
        <div style={{...wrapper, width: `${props.width}px`}}>
            {allCircles.map((circle, index) => {
                if(circle === "big") {
                    return (
                        <div key={index} style={{...circleStyle, ...big}} />
                    )
                } else {
                    return (
                        <div key={index} style={{...circleStyle, ...small}} />
                    )
                }
            })}
            <div style={indicatorWrapper}>
                <div style={{width: `${scrollPosition - 10}%`}}></div>
                <Pacman minWidth="40px" minHeight="40px"/>
            </div>
        </div>

    )
}

const wrapper: CSSProperties = {
    display: "flex", 
    alignItems: "center",
    justifyContent: "space-between", 
    position: "fixed",
    left: 0,
    right: 0,
    margin: "auto",
    top: "5%",
    zIndex: 99
}

const indicatorWrapper: CSSProperties = {
    position: "absolute", 
    display: "flex", 
    width: "100%"
}

const circleStyle: CSSProperties = {
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    backgroundColor: "white",
}

const big: CSSProperties = {
    width: "25px",
    height: "25px"
}
const small: CSSProperties = {
    width: "10px",
    height: "10px"
}

