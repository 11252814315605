import { Layout } from './components/layout';
import { BrowserRouter } from "react-router-dom";
import { ScrollProvider } from "./contexts"
import { ViewportProvider } from './contexts/viewportContext';
import { polyfill } from 'smoothscroll-polyfill';
import { useEffect } from 'react';
import { UiLayer } from './components/ui/uiLayer';

function App() {

  useEffect(() => {
    polyfill();
  }, [])

  return (
    <BrowserRouter>
      <ViewportProvider>
        <ScrollProvider>
         
          <UiLayer />
          <Layout />

        </ScrollProvider>
      </ViewportProvider>
    </BrowserRouter>
  );
}

export default App;
